@import "../variables.scss";
@import "../mixins.scss";
.container {
  min-height: calc(100vh - 200px);
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;

  .box {
    width: 450px;
    background-color: rgba(186, 178, 178, 0.372);
    border-radius: 4px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;

    @include mq(md) {
      width: 320px;
      padding: 10px;
    }
    .title {
      color: orange;
      font-size: 16px;
      font-weight: 400;
      text-align: center;
      .span {
        color: rgb(186, 178, 178);
      }
    }
    .box-input {
      width: 100%;
      .box-textarea {
        .text-message {
          width: 100%;
        }
      }
      .input {
        height: 32px;
        width: 100%;
        padding: 4px;
      }
      .error {
        font-size: 12px;
        color: red;
        position: absolute;
        left: 0;
        bottom: -20px;
      }
    }
  }
}
