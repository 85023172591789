.btn-component {
  min-height: 30px;
  width: 120px;
  background-color: rgb(20, 20, 20);
  border: 1px solid orange;
  border-radius: 4px;
  padding: 6px;
  color: orange;
  position: relative;
  overflow: hidden;
  &:hover {
    color: white;
    background-color: orange;
  }
}
