@import "https://fonts.googleapis.com/css2?family=Inter:wght@200;400;700;800&family=Lobster&family=Montserrat:ital,wght@0,300;0,400;1,200&display=swap";

body {
  background-color: rgb(20, 20, 20);
  font-family: Inter, sans-serif;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
a {
  color: black;
  text-decoration: none;
}
ul {
  list-style: none;
}
button {
  cursor: pointer;
}
