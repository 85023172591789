@import "../variables.scss";
@import "../mixins.scss";

.hamburger {
  height: 30px;
  width: 30px;
  padding: 2px;
  flex-direction: column;
  justify-content: space-around;
  display: none;
  @include mq(sm) {
    display: flex;
  }
  .bar {
    width: 26px;
    height: 3px;
    border-radius: 2px;
    background-color: orange;
  }
}

.list-item {
  display: flex;
  gap: 20px;
  @include mq(sm) {
    padding: 10px;
    background-color: rgb(20, 20, 20);
    flex-direction: column;
    gap: 5px;
    position: absolute;
    bottom: -100px;
    right: 0;
    z-index: 10;
  }
  .item {
    color: white;
    &:hover {
      color: orange;
    }
  }
}
