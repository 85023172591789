@mixin mq($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media ( max-width: #{ map-get( $breakpoints, $breakpoint ) } ) {
      @content;
    }
  } @else if
    type_of($breakpoint) ==
    number and
    unit($breakpoint) ==
    px or
    unit($breakpoint) ==
    em or
    unit($breakpoint) ==
    rem
  {
    @media (max-width: $breakpoint) {
      @content;
    }
  } @else {
    @warn "Nie można pobrać żadnej wartości z `#{$breakpoint}`. Nie jest zdefiniowany w mapie `$breakpoints` lub jest nieprawidłową liczbą px, em, lub rem.";
  }
}
