@import "../variables.scss";
@import "../mixins.scss";

.container-about {
  min-height: calc(100vh - 200px);
  display: flex;
  justify-content: space-between;
  gap: 20px;

  @include mq(md) {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .icons-skills {
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: center;
    align-items: center;
    @include mq(md) {
      flex-direction: row;
      align-items: center;
      gap: 10px;
    }

    .icon {
      font-size: 30px;
      color: orange;
      transition: 0.3s ease-in;
      &:hover {
        scale: 1.1;
      }
    }
    .box-icon {
      width: 30px;
      height: 30px;

      transition: 0.3s ease-in;
      &:hover {
        scale: 1.1;
      }
    }
  }

  .box-img {
    height: 350px;
    min-width: 400px;
    animation: fadeIn 3s forwards linear;
    // border-radius: 2px;
    border-radius: 49% 51% 72% 28% / 30% 34% 66% 70%;
    // border-radius: 49% 51% 56% 44% / 30% 38% 62% 70%;
    overflow: hidden;
    @include mq(md) {
      zoom: 0.7;
    }
    @include mq(xs) {
      zoom: 0.55;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 100%;
    }
  }

  .box-about {
    width: 350px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    @include mq(md) {
      width: 300px;
      gap: 15px;
    }
    @include mq(xs) {
      width: 230px;
      gap: 10px;
    }
    .title {
      font-size: 50px;
      color: white;
      @include mq(md) {
        font-size: 35px;
      }
    }
    .title-position {
      font-size: 50px;
      color: orange;
      @include mq(md) {
        font-size: 35px;
      }
    }
    .text {
      color: white;
      @include mq(md) {
        font-size: 12px;
      }
    }
  }
}
